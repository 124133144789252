// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-categories-js": () => import("../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-tags-js": () => import("../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faqs-index-js": () => import("../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paperfaces-js": () => import("../src/pages/paperfaces.js" /* webpackChunkName: "component---src-pages-paperfaces-js" */),
  "component---src-pages-procreate-paintings-js": () => import("../src/pages/procreate-paintings.js" /* webpackChunkName: "component---src-pages-procreate-paintings-js" */),
  "component---src-pages-tag-js": () => import("../src/pages/tag.js" /* webpackChunkName: "component---src-pages-tag-js" */),
  "component---src-pages-tiny-paintings-js": () => import("../src/pages/tiny-paintings.js" /* webpackChunkName: "component---src-pages-tiny-paintings-js" */),
  "component---src-pages-work-js": () => import("../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

